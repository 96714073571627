





































































































.items {
  .item {
    border-bottom: 1px solid #e5e5e5;

    &-property {
      color: #000000;
      font-family: "Raleway Regular", serif;
      font-size: 16px;
    }

    &-value {
      color: #000000;
      font-family: "Raleway Bold", serif;
      font-size: 16px;
    }
  }
}
